@import "bootstrap/scss/functions";
@import "./custom_variables.scss";
@import "./bootstrap/scss/bootstrap.scss";

.parallax-header {
	background: transparent;
	position: relative;
    height: 400px;
    max-height: 50vh;
	margin-bottom: 2rem;
}

.parallax-insert {
	background: transparent;
	position: relative;
    height: 400px;
    max-height: 50vh;

}

.page-title {
	letter-spacing: 0.6rem;
	font-weight: 100;
	font-size: 3.3rem;
	text-transform: uppercase;
	border-top: 1px solid #422635;
	border-bottom: 1px solid #422635;
	display: inline-block;
	padding: 0.6rem 0 0.6rem 0;
	margin-bottom: 2rem;
}

.page-subtitle {
	letter-spacing: 0.07rem;
	font-weight: 300;
}

.subtitle {
	margin-bottom: 1rem;
	font-weight: 400;
	letter-spacing: 0.03rem;
}

.txt p {
	margin-bottom: 1.2rem;
}
/*rgba(37, 181, 44, 0.1);*/
.qwot {
	background-color: #efefef;
	padding: 1.5rem 1.5rem 1rem 2rem;
	margin-bottom: 2rem;
}

.qwot p {
	font-size: 1.2rem;
	font-style: italic;
}

.footnotes li {
	font-size: 0.9rem;
}

/* these styles are for mobile phones */
@media (max-width: 575px) {
	.page-title {
		font-size: 2.3rem;
	}

	.parallax-header {
		margin-bottom: 1rem;
	}
}

@media (min-width: 992px) {
	.navbar-light .navbar-nav .nav-link.active {
		background-color: #fff;
		color: #25B52C;
		padding-top: 0rem;
		padding-left: 0.7rem;
		padding-right: 0.7rem;
		font-size: 1.1rem;
	}

	.navbar-light .navbar-nav .nav-link {
		padding-top: 0rem;
		padding-left: 0.7rem;
		padding-right: 0.7rem;
		font-size: 1.1rem;
		color: #422635;
	}

	a.nav-link:hover {
		color: #25B52C;
	}

	.nav-bg {
		padding-bottom: 0;
		padding-top: 0;
	}

	.navbar-brand {
		margin-right:5vw;
	}
}

@media (min-width: 1600px) {
	.container.wide {
	    max-width: 1540px;
	}
}

@media (min-width: 2000px) {
	.container.wide {
	    max-width: 1940px;
	}
}

// a smaller percentage width moves the arrows closer to the edge
.carousel-control-prev,
.carousel-control-next {
	width: 10%;
	opacity: .8;
	.hover-focus {
      opacity: 1;
    }
}
.carousel-control-next-icon {
  background-image: url('../images/right-thin-chevron-svgrepo-com.svg');
  height: 3rem !important;
  width: 3rem !important;
}

.carousel-control-prev-icon {
  background-image: url('../images/left-thin-chevron-svgrepo-com.svg');
  height: 3rem !important;
  width: 3rem !important;
}

.nav-bg {
	background-color: #fff;
}

header {
	margin-bottom: .2rem;
}

.footer {
	margin-top: 5rem;
}

.footer-bg {
	background-color: #25B52C;
}

.text-white-80 {
	color: rgba(255,255,255,.8)!important;
}

.table-cell-right td, .table-cell-right th {
	text-align: right;
}

label {
	font-size: 0.8rem;
	margin-bottom:0.3rem
}

.form-group.required label:after {
  content:" *";
  color:red;
}
